import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, Button, Input, message, ColorPicker, Select } from "antd";
import { useAxios } from "common";
import "antd/dist/reset.css";
import "./Calendar.css";
import moment from "moment";

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [eventColor, setEventColor] = useState("#1890ff");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [filter, setFilter] = useState("all");

  const { callAxios, loading, error } = useAxios();

  useEffect(() => {
    fetchEvents();
  }, [filter]);

  const fetchEvents = async () => {
    try {
      const response = await callAxios({
        url: "/calenderEvent",
        method: "GET",
        params: { onlyEdited: filter === "edited" ? "true" : "false" },
      });

      const formattedEvents = response.data.map((event) => ({
        id: event._id,
        title: event.name,
        start: new Date(event.date).toISOString(),
        color: event.color || "#1890ff",
        manualEdit: event.manualEdit || false,
        extendedProps: {
          brand: event.brand,
        },
      }));

      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
    setEventTitle(info.event.title);
    setEventColor(info.event.backgroundColor || "#1890ff");
    setIsModalVisible(true);
  };

  const handleEventDrop = async (info) => {
    const updatedDate = info.event.startStr;

    try {
      await callAxios({
        url: `/events/${info.event.id}`,
        method: "PUT",
        data: {
          date: updatedDate,
          manualEdit: true,
        },
      });

      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === info.event.id
            ? {
                ...event,
                start: updatedDate,
                manualEdit: true,
              }
            : event,
        ),
      );

      message.success("Event date updated successfully!");
    } catch (error) {
      console.error("Error updating event:", error);
      message.error("Failed to update event date.");
    }
  };

  const handleModalOk = async () => {
    if (!eventTitle.trim()) {
      message.error("Event title cannot be empty!");
      return;
    }

    const selectedHexColor =
      typeof eventColor === "string" ? eventColor : eventColor.toHexString();

    const updatedEvent = {
      name: eventTitle,
      color: selectedHexColor,
      manualEdit: true,
    };

    try {
      await callAxios({
        url: `/events/${selectedEvent.id}`,
        method: "PUT",
        data: updatedEvent,
      });

      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === selectedEvent.id
            ? {
                ...event,
                title: eventTitle,
                color: selectedHexColor,
                manualEdit: true,
              }
            : event,
        ),
      );

      message.success("Event updated!");
    } catch (error) {
      console.error("Error updating event:", error);
      message.error("Something went wrong!");
    }

    setIsModalVisible(false);
    setSelectedEvent(null);
  };

  const renderEventContent = (eventInfo) => {
    if (!eventInfo || !eventInfo.event) {
      console.warn("Event info is missing:", eventInfo);
      return null;
    }

    return (
      <div
        style={{
          textAlign: "center",
          padding: "8px",
          wordWrap: "break-word",
          whiteSpace: "normal",
          backgroundColor: eventInfo.event.backgroundColor || "#1890ff",
          color: "#fff",
          fontWeight: "bold",
          fontSize: "14px",
          borderRadius: "4px",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ color: "#fff" }}>{eventInfo.event.title}</span>
      </div>
    );
  };

  return (
    <div className="calendar-container">
      <div
        style={{
          marginBottom: "10px",
          textAlign: "center",
          position: "relative",
          top: "50px",
        }}
      >
        <Select value={filter} onChange={setFilter} style={{ width: 200 }}>
          <Select.Option value="all">All Events</Select.Option>
          <Select.Option value="edited">Only Edited Events</Select.Option>
        </Select>
      </div>

      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventClick={handleEventClick}
        eventDrop={handleEventDrop}
        editable={true}
        eventContent={renderEventContent}
        height="auto"
        contentHeight="auto"
        views={{
          dayGridMonth: {
            showNonCurrentDates: false,
            fixedWeekCount: false,
          },
        }}
      />

      <Modal
        title="Edit Event"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleModalOk}>
            Update
          </Button>,
        ]}
      >
        <Input
          placeholder="Event Title"
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
        />
        <p style={{ marginTop: "10px", marginBottom: "5px" }}>
          Select Event Color:
        </p>
        <ColorPicker
          value={eventColor}
          onChange={(color) => setEventColor(color.toHexString())}
        />
      </Modal>
    </div>
  );
};

export default Calendar;

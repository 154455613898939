import { formatNumber, formatPercent, RowFlex, theme } from "common";
import {
  GoalKpi,
  GoalProgress,
  GoalViewContainer,
  GoalViewName,
} from "./styles";
import { format } from "date-fns";
import { FaEdit, FaTrash } from "react-icons/fa";

const ProgressView = ({
  title,
  onClick,
  currentAmount,
  sos,
  goalAmount,
  onDelete,
  loading,
}) => {
  return (
    <GoalViewContainer>
      <GoalViewName extend>
        <div>{title}</div>
        {onClick && !loading && (
          <FaEdit
            fill={theme.colors.primary}
            size="0.7rem"
            cursor="pointer"
            onClick={onClick}
          />
        )}
        {onDelete && !loading && (
          <FaTrash
            fill={theme.colors.danger}
            size="0.7rem"
            cursor="pointer"
            onClick={onDelete}
          />
        )}
      </GoalViewName>
      <RowFlex extend>
        <GoalKpi title="Current" value={`$ ${formatNumber(currentAmount)}`} />
        <GoalKpi
          title="SOs"
          value={formatNumber(sos.length)}
          tooltip={
            <div>
              {sos.map((so) => (
                <div style={{ color: theme.colors.white }} key={so.OrderNumber}>
                  {so.OrderNumber}
                </div>
              ))}
            </div>
          }
        />
        <GoalKpi title="Goal" value={`$ ${formatNumber(goalAmount)}`} />
      </RowFlex>
      <GoalProgress percent={formatPercent(currentAmount, goalAmount)} />
    </GoalViewContainer>
  );
};

const GoalView = ({ goal, onEdit, onDelete, loading }) => {
  return (
    <ProgressView
      onDelete={onDelete}
      loading={loading}
      onClick={() => onEdit(goal)}
      {...goal}
      title={`${goal.brand.name} - ${format(
        new Date(goal.month),
        "MMMM yyyy"
      )}`}
    />
  );
};

export { GoalView, ProgressView };

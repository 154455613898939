import {
  Button,
  Column,
  downloadFile,
  Dropdown,
  FileUpload,
  FormikSubmit,
  FormWrapper,
  generateLinkWithParams,
  ImageUpload,
  ItemSplitter,
  Link,
  linkPlaceholders,
  PageContainer,
  routing,
  TextField,
  useAxios,
  useBrands,
  useCategories,
  useCurrencies,
  useLoginContext,
  useSuggestBrandListModal,
} from "common";
import * as Yup from "yup";
import { useToast } from "common/context/Toast";
import { Field, Formik, getIn } from "formik";
import { __, clone, dissoc } from "ramda";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "./styles.js";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  category: Yup.string().required("Category is required"),
  suggestedMargin: Yup.number().required("Suggested Margin is required"),
  minimumMargin: Yup.number()
    .nullable()
    .min(0)
    .max(99.9999999)
    .required("Minimum Margin is required"),
  maximumMargin: Yup.number()
    .nullable()
    .min(0)
    .max(99.9999999)
    .required("Maximum Margin is required"),
  shippingCost: Yup.number().nullable().required("Shipping Cost is required"),
  commissionCost: Yup.number()
    .nullable()
    .required("Commission Cost is required"),
  minimumOrderDollarAmount: Yup.number()
    .nullable()
    .required("Minimum Order Amount is required"),
  skuassortment: Yup.string().nullable().required("SKU Assortment is required"),
  soldByCaseOnly: Yup.boolean()
    .required("Sold By Case Only is required")
    .nullable(),
  fobPoint: Yup.string().nullable().required("FOB Point is required"),
  leadTime: Yup.string().nullable().required("Lead Time is required"),
  cutoffdate: Yup.number()
    .nullable()
    .min(0, "Cutoff date must be between 0 and 30")
    .max(30, "Cutoff date must be between 0 and 30")
    .notRequired(),
});

const BrandForm = ({ isEdit, formikSchema, parentBrand }) => {
  const [val, setVal] = useState({
    CutoffDate: false,
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const { currentUser } = useLoginContext();
  function handleOnChange(e, form) {
    if (e < 0 || e > 30) {
      setVal({ ...val, CutoffDate: true });
    } else {
      setVal({ ...val, CutoffDate: false });
    }
  }
  const confirmDeleteBrand = () => {
    setShowDeleteConfirm(true);
  };

  const { onError, alertSuccess } = useToast();
  const { brandId } = useParams();
  const initialIsOverseasValue = formikSchema.initialValues.isOverseas;

  const [overseasChecked, setOverseasChecked] = useState(
    initialIsOverseasValue,
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const goBack = (id) =>
    navigate(
      !id
        ? routing.brands.root
        : generateLinkWithParams(routing.brands.view, {
            [linkPlaceholders.brandId]: id,
          }),
    );
  const { callAxios } = useAxios();
  const { callAxios: getSampleFile, loading: loadingSampleFile } = useAxios({
    onComplete: ({ data: sampleFile }) => {
      downloadFile(sampleFile);
    },
  });

  const { currencies, currencyOptions } = useCurrencies();

  const { categories: categoryOptions } = useCategories();

  const { brands: brandOptions } = useBrands();

  const getCurrencyObject = (code) => currencies.find((c) => c.code === code);

  const handleSubmitEdit = async (values) => {
    return callAxios({
      method: "PUT",
      url: `/brands/${values.id}`,
      data: dissoc("id", {
        ...values,
        currency: getCurrencyObject(values.currency),
        isOverseas: overseasChecked,
      }),
    });
  };
  const handleDelete = async () => {
    setShowDeleteConfirm(false); // Close modal first
    setLoading(true);
    try {
      await callAxios({
        method: "DELETE",
        url: `/brands/${brandId}`,
      });
      alertSuccess("Brand deleted successfully.");
      // 👇 Give a short timeout to unmount the component smoothly
      setTimeout(() => navigate(routing.brands.root), 200);
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  const suggestCreateBrandList = useSuggestBrandListModal();

  const handleSubmit = async (oldValues) => {
    const values = {
      ...clone(oldValues),
      currency: getCurrencyObject(oldValues.currency),
      status: oldValues.status,
    };
    if (values.image) {
      const imageFile = new File(
        [values.image],
        `${values.name}_image`.replace(/ /g, "_").toLowerCase() +
          values.image.type.replace("image/", "."),
      );
      const data = new FormData();
      data.append("file", imageFile);
      const res = await callAxios({
        method: "POST",
        url: "/files/upload",
        data,
        headers: {
          "Content-Type": "blob",
        },
      });
      values.image = res.data.filename;
    }
    return callAxios({
      method: "POST",
      url: "/brands",
      data: dissoc("lineItemsFile", values),
    });
  };

  const handleFileUpload = async (file, fileBrandId) => {
    if (file) {
      const data = new FormData();
      data.append("itemsExcel", file);
      await callAxios({
        method: "POST",
        url: `/brands/${fileBrandId}/upload`,
        data,
        headers: {
          "Content-Type": "blob",
        },
      });
    }
  };

  const generateLink = generateLinkWithParams(__, {
    [linkPlaceholders.brandId]: brandId,
  });
  const isAdminUser =
    currentUser?.email === "simon@hbamfl.com" ||
    currentUser?.email === "joseph@hbamfl.com";
console.log(currentUser,'ads;flkaj;sdfklaj;sdfklaj;sdf')
  return (
    <PageContainer>
      <h1>
        {parentBrand
          ? `Add Pricesheet to ${parentBrand}`
          : isEdit
            ? "Update Brand"
            : "Add Brand"}
      </h1>
      <Formik
        enableReinitialize
        {...formikSchema}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={(values) => {
          setLoading(true);
          const submitFunction = isEdit ? handleSubmitEdit : handleSubmit;
          submitFunction(values)
            .then(async (res) => {
              const id = getIn(res, "data.id");
              const newBrand = res.data;
              setLoading(false);
              if (values.lineItemsFile) {
                setLoading(true);
                handleFileUpload(values.lineItemsFile, id)
                  .then(async () => {
                    alertSuccess(
                      `Brand Successfully ${isEdit ? "Update" : "Create"}d!`,
                    );
                    goBack(id);
                  })
                  .catch(() => {
                    goBack(id);
                  })
                  .finally(() => setLoading(false));
              } else {
                alertSuccess(
                  `Brand Successfully ${isEdit ? "Update" : "Create"}d!`,
                );
                if (newBrand?.category) {
                  const { data } = await callAxios({
                    method: "POST",
                    url: "/brandList/exists",
                    data: { name: newBrand.name },
                  });
                  // if (!data) suggestCreateBrandList({ data: newBrand });
                }
                goBack(id);
              }
            })
            .catch((err) => {
              onError(err);
              setLoading(false);
            });
        }}
      >
        <FormWrapper>
          <Form style={{ display: "flex" }}>
            <Column style={{ width: "100%" }}>
              <ItemSplitter style={{ display: "flex", gap: "16px" }}>
                <Field
                  name="category"
                  component={Dropdown}
                  label="Category"
                  options={categoryOptions}
                  required
                />
                <Field
                  name="status"
                  component={Dropdown}
                  label="status"
                  options={[
                    { value: "approved", label: "Approved" },
                    { value: "unapproved", label: "Unapproved" },
                  ]}
                  disabled={!isAdminUser}
                />
                {!parentBrand && (
                  <Field
                    name="parentBrand"
                    component={Dropdown}
                    label="Parent Brand"
                    options={brandOptions}
                  />
                )}
                {isAdminUser && (
                  <Field
                    name="dealByDeal"
                    component={Dropdown}
                    label="Deal by Deal"
                    options={[
                      { value: true, label: "On" },
                      { value: false, label: "Off" },
                    ]}
                    onChange={(value) => {
                      console.log("Deal by Deal is:", value);
                    }}
                  />
                )}
              </ItemSplitter>
              <ItemSplitter>
                <Field
                  name="name"
                  component={TextField}
                  label={parentBrand ? "Pricesheet Name" : "Name"}
                  required
                  fillWidth
                  allBorders
                />
                <Field
                  name="tabName"
                  component={TextField}
                  label={"Tab Name"}
                  fillWidth
                  allBorders
                />
              </ItemSplitter>
              <ItemSplitter
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Field
                  name="currency"
                  component={Dropdown}
                  label="Currency"
                  options={currencyOptions}
                  required
                  style={{ flex: 1 }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "15px",
                    alignItems: "flex-start",
                  }}
                >
                  <label
                    htmlFor="overseas"
                    style={{
                      marginBottom: "5px",
                      marginLeft: "100px",
                    }}
                  >
                    <span style={{ marginLeft: "235px" }}> Overseas </span>
                  </label>
                  <div style={{ marginLeft: "100px" }}>
                    <Field
                      name="isOverseas"
                      component={({ field, form }) => (
                        <input
                          type="checkbox"
                          id="isOverseas"
                          checked={field.value}
                          onChange={(e) => {
                            form.setFieldValue(field.name, e.target.checked);
                            setOverseasChecked(e.target.checked);
                          }}
                          style={{
                            marginLeft: "265px",
                            marginTop: "6px",
                            transform: "scale(1.2)",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div
                  style={{
                    marginLeft: "140px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <Field
                    name="cutoffdate"
                    component={TextField}
                    label="Cutoff Date (Please Enter number between 0 to 30)"
                    type="number"
                    fillWidth
                    allBorders
                    onChange={(e, form) => handleOnChange(e, form)}
                  />

                  {val.CutoffDate && (
                    <p
                      style={{
                        position: "absolute",
                        bottom: "-15px",
                        left: "0",
                        color: "red",
                        fontSize: "13px",
                      }}
                    >
                      Number should between 0 & 30{" "}
                    </p>
                  )}
                </div>
              </ItemSplitter>

              <Field
                name="suggestedMargin"
                component={TextField}
                label="Suggested Margin %"
                type="number"
                required
                fillWidth
                allBorders
              />
              <ItemSplitter>
                <Field
                  name="minimumMargin"
                  component={TextField}
                  label="Min. Margin %"
                  type="number"
                  required
                  fillWidth
                  allBorders
                />

                <Field
                  name="maximumMargin"
                  component={TextField}
                  label="Max. Margin %"
                  type="number"
                  fillWidth
                  allBorders
                  required
                />
              </ItemSplitter>
              {overseasChecked ? (
                <ItemSplitter>
                  <Field
                    name="shippingCost"
                    component={TextField}
                    label="Shipping Cost %"
                    type="number"
                    fillWidth
                    allBorders
                    required
                  />
                  <Field
                    name="overseasShippingCost"
                    component={TextField}
                    label="Overseas Shipping Cost %"
                    type="number"
                    fillWidth
                    allBorders
                  />
                </ItemSplitter>
              ) : (
                <Field
                  name="shippingCost"
                  component={TextField}
                  label="Shipping Cost %"
                  type="number"
                  fillWidth
                  allBorders
                  required
                />
              )}
              <Field
                name="commissionCost"
                component={TextField}
                label="Commission Cost %"
                type="number"
                fillWidth
                allBorders
                required
              />

              <Field
                name="otherCosts"
                component={TextField}
                label="Other Costs %"
                type="number"
                fillWidth
                allBorders
              />
              <ItemSplitter>
                <Field
                  name="minimumOrderDollarAmount"
                  component={TextField}
                  label="Min Order Amount"
                  type="number"
                  fillWidth
                  allBorders
                  required
                />
                <Field
                  name="skuassortment"
                  component={TextField}
                  label="SKU Assortment"
                  fillWidth
                  allBorders
                  required
                />
              </ItemSplitter>

              <Field
                name="maximumOrderDollarAmount"
                component={TextField}
                label="Max Order Amount"
                type="number"
                fillWidth
                allBorders
              />
              {/* <Field
                  name="maximumOrderItems"
                  component={TextField}
                  label="Max Order Items"
                  type="number"
                  fillWidth
                  allBorders
                /> */}

              <Field
                name="soldByCaseOnly"
                component={Dropdown}
                label="Sold By Case Only"
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                required
              />
              <ItemSplitter>
                <Field
                  name="fobPoint"
                  component={TextField}
                  label="Fob Point"
                  fillWidth
                  allBorders
                  required
                />
                <Field
                  name="leadTime"
                  component={TextField}
                  label="Lead Time"
                  fillWidth
                  allBorders
                  required
                />
              </ItemSplitter>
              <ItemSplitter>
                <Field
                  name="specialDiscountNotes"
                  component={TextField}
                  label="Special Discount Notes"
                  fillWidth
                  allBorders
                />
                <Field
                  name="notes"
                  component={TextField}
                  label="Internal Notes"
                  fillWidth
                  allBorders
                />
              </ItemSplitter>
              <ItemSplitter>
                <Field
                  name="notesForCustomers"
                  component={TextField}
                  label="Notes For Customers"
                  fillWidth
                  allBorders
                />
                <Field
                  name="directOrVendor"
                  component={TextField}
                  label="Direct / Vendor"
                  fillWidth
                  allBorders
                />
              </ItemSplitter>
              <ItemSplitter>
                <Field
                  name="repCoveringBrand"
                  component={TextField}
                  label="Rep Covering Brand"
                  fillWidth
                  allBorders
                />
              </ItemSplitter>
              <ItemSplitter
                style={{ display: "flex", justifyContent: "end" }}
                autoWidth
              >
                <FormikSubmit loading={loading}>
                  {isEdit ? "Update" : "Add"}
                </FormikSubmit>
                <Button secondary onClick={() => goBack(brandId)}>
                  Cancel
                </Button>
              </ItemSplitter>
            </Column>
            {isEdit ? (
              <Column style={{ fontSize: "0.75rem" }}>
                <div>
                  <Link to={generateLink(routing.brands.changeImage)}>
                    <Button secondary>Change Image</Button>
                  </Link>
                </div>
                <div>
                  <Link to={generateLink(routing.brands.changeFile)}>
                    <Button secondary>Upload Items</Button>
                  </Link>
                </div>
                <div>
                  <Link to={generateLink(routing.brands.attachments)}>
                    <Button secondary>View / Edit Attachments</Button>
                  </Link>
                </div>
                <div>
                  <Button secondary onClick={confirmDeleteBrand}>
                    Delete Brand
                  </Button>
                </div>
              </Column>
            ) : (
              <Column>
                <Field name="image" component={ImageUpload} label="Image" />
                <h3>Brand Items</h3>
                <ItemSplitter>
                  <Field name="lineItemsFile" component={FileUpload} />
                  <Button
                    loading={loadingSampleFile}
                    secondary
                    onClick={() => {
                      getSampleFile({
                        method: "GET",
                        url: "/brands/downloadSampleExcel",
                        responseType: "blob",
                      });
                    }}
                  >
                    Download Sample
                  </Button>
                </ItemSplitter>
              </Column>
            )}
          </Form>
        </FormWrapper>
      </Formik>
      {showDeleteConfirm && (
        <div
          onClick={() => setShowDeleteConfirm(false)}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "24px 32px",
              borderRadius: "8px",
              boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
              minWidth: "320px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h3
              style={{
                marginBottom: "16px",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Confirm Delete
            </h3>
            <p style={{ marginBottom: "24px" }}>
              Are you sure you want to delete this brand? This action cannot be
              undone.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Button secondary onClick={() => setShowDeleteConfirm(false)}>
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "#f44336",
                  color: "#fff",
                  padding: "8px 16px",
                  borderRadius: "6px",
                  fontWeight: 600,
                }}
                onClick={handleDelete}
              >
                Yes, Delete
              </Button>
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default BrandForm;

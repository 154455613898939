const eBrandGoalType = {
  CLOSED: "Closed",
  OPEN: "Open",
  NEW: "New",
};

const eBrandGoalTypeDescription = {
  Closed: "Goals for Brands with No Open POs",
  Open: "Goals for Brands with Open POs",
  New: "New Goals",
};

const poStatuses = {
  AWAITING_ACKNOWLEDGE: "Awaiting Acknowledge",
  DRAFT: "Draft",
  NOT_RECEIVED: "Not Received",
  RECEIVED: "Received",
  VOID: "Void",
  PARTIAL_RECEIVED: "Partial Received",
};

const poStatusMap = {
  9: poStatuses.AWAITING_ACKNOWLEDGE,
  0: poStatuses.DRAFT,
  1: poStatuses.NOT_RECEIVED,
  7: poStatuses.RECEIVED,
  4: poStatuses.RECEIVED,
  8: poStatuses.VOID,
  2: poStatuses.PARTIAL_RECEIVED,
};

const isBrandOpen = (b) =>
  b.purchaseOrders.some((po) =>
    [
      poStatuses.AWAITING_ACKNOWLEDGE,
      poStatuses.NOT_RECEIVED,
      poStatuses.PARTIAL_RECEIVED,
    ].includes(poStatusMap[po.Status])
  );

export { eBrandGoalType, poStatusMap, isBrandOpen, eBrandGoalTypeDescription };

import * as yup from "yup";

const validationSchema = yup.object({
  brand: yup.string().required(),
  month: yup.date().required(),
  goalAmount: yup.number().min(0).required(),
});

const formikSchema = (type, goal, thisDate) => ({
  initialValues: {
    type,
    brand: goal?.brand?.id ?? "",
    goalAmount: goal?.goalAmount,
    notes: goal?.notes,
    month: goal?.month || thisDate,
    customSos: goal?.customSos?.map((s) => s.id) || [],
  },
  validationSchema,
});

const formikSchemaForCreate = (type, thisDate) => ({
  initialValues: { brands: [{ type, brand: "", month: thisDate }] },
  validationSchema: yup.object({
    brands: yup.array().of(validationSchema).min(1).required(),
  }),
});

export { formikSchema, validationSchema, formikSchemaForCreate };

import axios from "axios";
import {
  Attachments,
  Button,
  Card,
  CollapsibleHeader,
  DatePicker,
  Dropdown,
  FormikSubmit,
  FullPageLoad,
  ItemSplitter,
  Link,
  RowFlex,
  Table,
  TextField,
  TextStyledLink,
  fetchImage,
  generateLinkWithParams,
  isKanda,
  linkPlaceholders,
  routing,
  theme,
  useAlertModal,
  useAxios,
  useCrmCustomers,
  useCurrencies,
  useLoginContext,
  useModalContext,
} from "common";
import VendorBrands from "common/modules/VendorBrands";
import { Field, Form, Formik } from "formik";
import { __ } from "ramda";
import { Fragment, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { baseURL } from "../../../../src/common/axios.js";
import EmailExportModal from "./EmailExportModal";
import ExcelView from "./ExcelView";
import {
  ViewCustomersLink,
  ViewOffersLink,
  ViewOrdersLink,
} from "./LinkButtons.js";
import Notes from "./Notes";
import { formikSchema } from "./formikSchema";
import Overseas from "./overseas";
import {
  BrandKpi,
  Container,
  DetailsContainer,
  ExportActions,
  ExportContainer,
  ExportTab,
  ExportTabs,
  FormWrapperHorizontal,
} from "./styles";

const ViewBrand = () => {
  const navigate = useNavigate();
  const { setModalContent } = useModalContext();
  const { isPriceSheetsAdmin } = useLoginContext();
  const { currencyOptions } = useCurrencies();
  const [pricesheetId, setPricesheetId] = useState();
  const [onExport, setOnExport] = useState(true);
  const [showChildBrands, setShowChildBrands] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const { crmCustomers } = useCrmCustomers();
  const { brandId } = useParams();
  const [prms] = useSearchParams();
  const customerFromParams = prms.get("customer");
  const customers = [customerFromParams].filter(Boolean);
  const ApproveButton = styled(Button)`
    background-color: #ff7f7f;
    color: white;
    padding: 8px 16px;
    border-radius: 6px;
    width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #ff5f5f;
    }
  `;

  const { refetch, response } = useAxios({
    clearResponse: false,
    callOnLoad: {
      method: "GET",
      url: `/brands/${brandId}`,
      params: {
        populate: JSON.stringify([
          { path: "pricesheets", populate: "childBrands parentBrand" },
          "childBrands",
          "parentBrand",
        ]),
      },
    },
  });
  const CustomDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background-color: rgb(242, 242, 242);
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 5px;
    transition: background-color 200ms ease;

    &:hover {
      background-color: white;
    }
  `;
  const CustomDiv1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(242, 242, 242);
    height: 150px;
    border-radius: 5px;
    transition: background-color 200ms ease;

    &:hover {
      background-color: white;
    }
  `;
  const { response: currentUser } = useAxios({
    clearResponse: false,
    callOnLoad: {
      method: "GET",
      url: `/auth/me`,
    },
  });
  const handleApprove = async () => {
    try {
      const response = await axios.put(
        `${baseURL}/brands/status/${parentBrand.id}`,
        {
          status: "approved",
        },
      );

      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error approving brand:", error);
      alert("Failed to approve brand.");
    }
  };
  const handleSetDefault = async (brandIdToSet) => {
    try {
      const response = await axios.put(
        `${baseURL}/brands/${brandIdToSet}/set-default`,
        { isDefault: true },
      );

      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSetDefaultWrapper = async (brandIdToSet) => {
    try {
      await handleSetDefault(brandIdToSet);
      window.location.reload();
    } catch (error) {
      console.error("Failed to set default brand:", error);
      alert("Something went wrong while setting the default brand.");
    }
  };

  const parentBrand = response?.data;
  const alert = useAlertModal("Please Ensure Up-front Payment");
  if (!parentBrand) return <FullPageLoad fillWidth />;

  if (parentBrand.brand)
    navigate(
      generateLinkWithParams(routing.brands.view, {
        [linkPlaceholders.brandId]: parentBrand.brand,
      }),
    );

  //const selectedPricesheetId = pricesheetId || parentBrand.id;
  const brandSelection = [parentBrand, ...(parentBrand.pricesheets || [])];
  const defaultBrand = brandSelection.find((b) => b.isDefault);
  const selectedPricesheetId =
    pricesheetId || defaultBrand?.id || parentBrand.id;

  const isParentBrand = selectedPricesheetId === parentBrand.id;

  //const brandSelection = [parentBrand, ...(parentBrand.pricesheets || [])];

  const brand = brandSelection.find((b) => b.id === selectedPricesheetId);
  const image = brand.image || parentBrand.image;
  const openModal = (params, show = true, childBrand = undefined) => {
    show &&
      setModalContent(
        <ExcelView
          brand={childBrand || brand}
          params={{ customers, ...params }}
        />,
      );
  };

  const updateNetherlandField = async (netherland) => {
    try {
      const updateUrl = `${baseURL}/netherland/${brand.id}`;
      await axios.put(updateUrl, { netherland: !netherland });
      // Handle any post-update logic here, if necessary
    } catch (error) {
      console.error("Failed to update netherland field:", error);
      // Handle error
    }
  };
  const isAdminUser =
    currentUser?.data?.email === "simon@hbamfl.com" ||
    currentUser?.data?.email === "joseph@hbamfl.com";

  const openOverseasModal = (margin) => {
    setModalContent(
      <Overseas
        brand={brand}
        margin={margin}
        onUpdateNetherland={updateNetherlandField}
        customers={customers}
      />,
    );
  };
  const linkParams = { [linkPlaceholders.brandId]: brand.id };
  const {
    currency: { symbol },
  } = brand;
  const hasMaxOrderDollarAmount = !!brand.maximumOrderDollarAmount;
  const amountRange = `${symbol}${numberWithCommas(
    brand.minimumOrderDollarAmount || 0,
  )}${
    hasMaxOrderDollarAmount
      ? ` - ${symbol}${numberWithCommas(brand.maximumOrderDollarAmount || 0)}`
      : ""
  }`;
  const hasMaxItemAmount = !!brand.maximumOrderItems;
  const itemRange = `${brand.minimumOrderItems || 0}${
    hasMaxItemAmount ? ` - ${brand.maximumOrderItems || 0}` : ""
  }`;
  const generateLink = generateLinkWithParams(__, linkParams);
  const suggestedMargin = isKanda()
    ? Math.max(brand.suggestedMargin, 30)
    : brand.suggestedMargin;
  const isApproved = parentBrand?.status?.toLowerCase() === "approved";
  const dealByDeal = brand?.dealByDeal || parentBrand?.dealByDeal;

  const margins = [
    {
      margin: brand.minimumMargin,
      show: !isKanda(),
      title: "Min. Margin",
    },
    {
      margin: suggestedMargin,
      show: true,
      title: "Suggested Margin",
    },
    {
      margin: brand.maximumMargin,
      show: !isKanda(),
      title: "Max. Margin",
    },
  ].map((margin) => {
    return (
      margin.show && (
        <CustomDiv
          key={margin.title}
          onClick={() => {
            if (dealByDeal) return;
            if (!isApproved && !isAdminUser) return;

            if (!parentBrand?.itemsHaveCostPrice) {
              return alert(
                "Not all items have cost price, please reupload the price sheet",
              );
            }

            if (
              parentBrand?.isOverseas &&
              parentBrand?.overseasShippingCost !== null
            ) {
              openOverseasModal(margin);
            } else {
              const open = () =>
                openModal(
                  { margin: margin.margin },
                  parentBrand?.itemsHaveCostPrice,
                );
              if (margin.margin < 12) {
                alert(null, open);
              } else {
                open();
              }
            }
          }}
          style={{
            cursor:
              (!isAdminUser && dealByDeal) || !isApproved
                ? "not-allowed"
                : "pointer",
            opacity: (!isAdminUser && dealByDeal) || !isApproved ? 0.6 : 1,
          }}
        >
          <h3>{margin.margin || 0}%</h3>
          <p>{margin.title}</p>
        </CustomDiv>
      )
    );
  });
  const disabledStyle = {
    cursor: !isAdminUser && dealByDeal ? "not-allowed" : "pointer",
    opacity: !isAdminUser && dealByDeal ? 0.7 : 1,
    pointerEvents: !isAdminUser && dealByDeal ? "none" : "auto",
  };

  return (
    <Container>
      {brandSelection.length > 1 && (
        <RowFlex
          style={{ gap: "16px", marginBottom: "24px", flexWrap: "wrap" }}
        >
          {brandSelection.map((b) => {
            const isCurrentDefault = b.isDefault;
            const isSelected = b.id === selectedPricesheetId;

            return (
              <div
                key={b.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  backgroundColor: isSelected ? "#fff3e6" : "#f9f9f9",
                  border: isSelected
                    ? "1.5px solid #ff8c00"
                    : isCurrentDefault
                      ? "1.5px solid #ff7f7f"
                      : "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "8px 14px",
                  transition: "all 0.2s ease",
                  boxShadow: isSelected
                    ? "0 2px 6px rgba(255, 140, 0, 0.2)"
                    : isCurrentDefault
                      ? "0 2px 6px rgba(255, 127, 127, 0.2)"
                      : "0 1px 3px rgba(0, 0, 0, 0.05)",
                  minWidth: "220px",
                }}
              >
                <Button
                  onClick={() => setPricesheetId(b.id)}
                  style={{
                    fontWeight: 600,
                    background: "none",
                    border: "none",
                    boxShadow: "none",
                    padding: "0",
                    color: isSelected
                      ? "#ff6b00"
                      : isCurrentDefault
                        ? "#e63946"
                        : "#333",
                    fontSize: "14.5px",
                    cursor: "pointer",
                    textDecoration: "underline",
                    textUnderlineOffset: "2px",
                    transition: "color 0.2s ease",
                  }}
                >
                  {b.tabName || b.name}
                  {isCurrentDefault && (
                    <span
                      style={{
                        fontWeight: "normal",
                        fontSize: "13px",
                        marginLeft: "6px",
                        color: isSelected ? "#ff6b00" : "#e63946",
                      }}
                    >
                      (default)
                    </span>
                  )}
                </Button>

                {!isCurrentDefault && isAdminUser && (
                  <Button
                    small
                    style={{
                      fontSize: "12px",
                      padding: "5px 12px",
                      backgroundColor: "#ff7f7f",
                      color: "#fff",
                      border: "none",
                      borderRadius: "6px",
                      cursor: "pointer",
                      fontWeight: 500,
                      transition: "background-color 0.2s ease",
                    }}
                    onClick={() => handleSetDefaultWrapper(b.id)}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#ff5f5f")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#ff7f7f")
                    }
                  >
                    Set as Default
                  </Button>
                )}
              </div>
            );
          })}
        </RowFlex>
      )}
      <h1>
        <RowFlex responsive>
          Brand Details{" "}
          {isPriceSheetsAdmin && (
            <Fragment>
              <Link
                to={generateLink(routing.brands.edit)}
                style={isAdminUser ? {} : disabledStyle}
              >
                (Edit)
              </Link>
              {isParentBrand && (
                <Link
                  to={{
                    pathname: routing.brands.add,
                    search: `?brand=${brand.id}`,
                  }}
                  style={isAdminUser ? {} : disabledStyle}
                >
                  (Add Pricesheet)
                </Link>
              )}
            </Fragment>
          )}
          {brand.parentBrand && (
            <Link
              to={generateLinkWithParams(routing?.brands?.view, {
                [linkPlaceholders?.brandId]: brand?.parentBrand?.id,
              })}
            >
              (Go to Parent - {parentBrand?.name})
            </Link>
          )}
          {brand.isInactive ? (
            <span style={{ color: theme.colors.danger }}>[Inactive]</span>
          ) : (
            <span>[Active]</span>
          )}
          <ViewCustomersLink
            dealByDeal={dealByDeal}
            isAdminUser={isAdminUser}
          />
          <ViewOrdersLink dealByDeal={dealByDeal} isAdminUser={isAdminUser} />
          <ViewOffersLink dealByDeal={dealByDeal} isAdminUser={isAdminUser} />
        </RowFlex>
      </h1>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <strong>{"Brand Status = " + parentBrand.status}</strong>
        {parentBrand.status === "unapproved" && isAdminUser && (
          <ApproveButton onClick={handleApprove}>Approve</ApproveButton>
        )}
        {!isAdminUser && dealByDeal && (
          <strong
            style={{
              marginLeft: "auto",
              backgroundColor: "rgba(255, 165, 0, 0.2)",
              padding: "8px 12px",
              borderRadius: "6px",
              color: "#ffa500",
              fontWeight: "bold",
              fontSize: "14px",
              border: "1px solid rgba(255, 165, 0, 0.3)",
            }}
          >
            Deal by Deal, please contact admin for pricing
          </strong>
        )}
      </div>

      <DetailsContainer>
        <CustomDiv>
          {image && (
            <img
              style={{ margin: "0" }}
              src={fetchImage(image)}
              alt="Brand"
              width="100px"
            />
          )}
          <h2>{brand.name}</h2>
          {isPriceSheetsAdmin && (
            <Link
              to={generateLink(routing.brands.changeImage)}
              style={!isAdminUser && dealByDeal ? disabledStyle : {}}
            >
              (Change Image)
            </Link>
          )}
        </CustomDiv>

        <ItemSplitter split={isKanda() ? 1 : 3}>{margins}</ItemSplitter>
        {!isKanda() && (
          <>
            <BrandKpi>
              <div style={{ display: "grid", height: "100%" }}>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Shipping Cost:
                  </span>
                  <strong>{brand.shippingCost}%</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Commission Cost:
                  </span>
                  <strong>{brand.commissionCost}%</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Other Costs:
                  </span>{" "}
                  <strong>{brand.otherCosts}%</strong>
                </p>
              </div>
            </BrandKpi>
            <BrandKpi>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  gap: "4px",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Cutoff Date:
                  </span>
                  <strong>{brand.cutoffdate}</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    SKU Assortment:
                  </span>
                  <strong>{brand.skuassortment}</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Min Order Amount:
                  </span>
                  <strong>{brand.minimumOrderDollarAmount}</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Lead Time:
                  </span>
                  <strong>{brand.leadTime}</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Special Discount:
                  </span>
                  <strong>{brand.specialDiscountNotes}</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Internal Notes:
                  </span>{" "}
                  <strong>{brand.notes}</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Notes For Customers:
                  </span>{" "}
                  <strong>{brand.notesForCustomers}</strong>
                </p>
              </div>
            </BrandKpi>
          </>
        )}

        <CustomDiv1>
          <h3>{amountRange}</h3>
          <p>{`Min.${hasMaxOrderDollarAmount ? " - Max." : ""} Amount`}</p>
        </CustomDiv1>
        <CustomDiv1>
          <h3>{itemRange}</h3>
          <p>{`Min.${hasMaxItemAmount ? " - Max." : ""} Number of Items`}</p>
        </CustomDiv1>
      </DetailsContainer>
      <div style={disabledStyle}>
        <Attachments
          url={`/brands/${brand.id}`}
          attachments={brand.attachments}
          onComplete={refetch}
        />

        <Notes brand={brand} />
        {currentUser?.data?.priceSheetsRole === "Admin" && (
          <VendorBrands id={brand.id} onComplete={refetch} />
        )}

        {isApproved && (
          <ExportContainer>
            <ExportTabs>
              <ExportTab
                isActive={onExport}
                onClick={() => {
                  setOnExport(true);
                }}
              >
                Export
              </ExportTab>
              {!isKanda() && (
                <ExportTab
                  isActive={!onExport}
                  onClick={() => {
                    setOnExport(false);
                  }}
                >
                  Email
                </ExportTab>
              )}
            </ExportTabs>
            <ExportActions>
              {onExport ? (
                <Formik
                  enableReinitialize
                  {...formikSchema(
                    brand,
                    currentUser?.data?.priceSheetsRole,
                    currentUser?.data?.email,
                    customers,
                  )}
                  onSubmit={(params) => {
                    const open = () => openModal(params);
                    if (params.margin < 12) return alert(null, open);

                    return open();
                  }}
                >
                  {({ handleChange, values }) => {
                    return (
                      <>
                        <FormWrapperHorizontal>
                          <Form>
                            <Field
                              name="margin"
                              label={`Profit Margin %${
                                !brand.itemsHaveCostPrice
                                  ? " (Items do not have Cost Price)"
                                  : ""
                              }`}
                              disabled={
                                !brand.itemsHaveCostPrice || values.isRaw
                              }
                              required
                              component={TextField}
                              type="number"
                              onFocus={() => {
                                handleChange({
                                  target: {
                                    name: "MSRPDiscount",
                                    value: undefined,
                                  },
                                });
                              }}
                            />
                            <Field
                              name="MSRPDiscount"
                              label={`Discount %${
                                !brand.itemsHaveMSRP
                                  ? " (Items do not have MSRP)"
                                  : ""
                              }`}
                              disabled={
                                !brand.itemsHaveMSRP ||
                                values.isRaw ||
                                isKanda()
                              }
                              component={TextField}
                              type="number"
                              onFocus={() => {
                                handleChange({
                                  target: { name: "margin", value: undefined },
                                });
                              }}
                            />
                            <Field
                              name="fromCurrency"
                              label="From Currency"
                              component={Dropdown}
                              options={currencyOptions}
                              disabled
                            />
                            <Field
                              name="toCurrency"
                              label="To Currency"
                              component={Dropdown}
                              options={currencyOptions}
                            />
                            <Field
                              name="customers"
                              label="Customer"
                              component={Dropdown}
                              isMulti
                              options={crmCustomers}
                            />
                            <Field
                              name="followUpDate"
                              label="Follow Up Date"
                              component={DatePicker}
                            />
                          </Form>
                          <FormikSubmit>Export</FormikSubmit>
                        </FormWrapperHorizontal>
                        <br />
                        <br />
                      </>
                    );
                  }}
                </Formik>
              ) : (
                <EmailExportModal
                  brand={brand}
                  customers={customers}
                  crmCustomers={crmCustomers}
                />
              )}
            </ExportActions>
          </ExportContainer>
        )}
        {brand.childBrands.length > 0 && (
          <CollapsibleHeader
            header="Child Brands"
            show={showChildBrands}
            setShow={setShowChildBrands}
          />
        )}
        {showChildBrands && (
          <Card>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  {!isKanda() && <th>Min. Margin</th>}
                  <th>Suggested Margin</th>
                  {!isKanda() && <th>Max. Margin</th>}
                </tr>
              </thead>
              <tbody>
                {brand.childBrands.map((childBrand) => {
                  const childSuggestedMargin = isKanda()
                    ? Math.max(childBrand.suggestedMargin, 30)
                    : childBrand.suggestedMargin;
                  const childMargins = [
                    {
                      margin: childBrand.minimumMargin,
                      show: !isKanda(),
                      title: "Min. Margin",
                    },
                    {
                      margin: childSuggestedMargin,
                      show: true,
                      title: "Suggested Margin",
                    },
                    {
                      margin: childBrand.maximumMargin,
                      show: !isKanda(),
                      title: "Max. Margin",
                    },
                  ].map((margin) => {
                    return (
                      margin.show && (
                        <BrandKpi
                          key={margin.title}
                          onClick={() => {
                            const open = () =>
                              openModal(
                                {
                                  margin: margin.margin,
                                },
                                childBrand.itemsHaveCostPrice,
                                childBrand,
                              );
                            if (margin.margin < 12) {
                              alert(null, open);
                            } else {
                              open();
                            }
                          }}
                        >
                          <h3>{margin.margin || 0}%</h3>
                          <p>{margin.title}</p>
                        </BrandKpi>
                      )
                    );
                  });
                  return (
                    <tr key={childBrand.id}>
                      <td>
                        <Link
                          to={generateLinkWithParams(routing.brands.view, {
                            [linkPlaceholders.brandId]: childBrand.id,
                          })}
                        >
                          {childBrand.name}
                        </Link>
                      </td>
                      {childMargins}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        )}
        <CollapsibleHeader
          header="More Options"
          show={showMoreOptions}
          setShow={setShowMoreOptions}
        />
        {showMoreOptions && (
          <div>
            <TextStyledLink
              onClick={() => {
                const params = {
                  isRaw: true,
                  customers: [],
                };
                setModalContent(<ExcelView brand={brand} params={params} />);
              }}
            >
              Export Original Vendor Cost
            </TextStyledLink>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ViewBrand;
export { default as ViewBrandCustomers } from "./ViewCustomers";
export { default as ViewBrandOrders } from "./ViewOrders";
export { default as ViewBrandOffers } from "./ViewOffers";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

import { RowFlex, theme } from "common";
import { lighten } from "polished";
import Tooltip from "rc-tooltip";
import styled from "styled-components";

const GoalViewContainer = styled.div`
  padding: 0.5rem;
  width: 100%;
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.lighterGrey};
  border-radius: 6px;
`;

const GoalViewName = styled(RowFlex)`
  font-weight: bold;
  font-size: 1.2rem;
`;

const GoalKpiContainer = styled.div`
  font-size: 0.8rem;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.lightestGrey};
  padding: 0.5rem 1rem;
  text-align: center;
`;

const GoalProgressBar = styled.div`
  width: 100%;
  height: 20px;
  position: relative;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.lighterGrey};
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: bold;
  overflow: hidden;
`;

const GoalProgressBarPercent = styled.div`
  width: ${({ percent }) => percent}%;
  height: 100%;
  background: ${({ color }) => color};
  border-radius: 6px;
`;

const GoalProgressBarLabel = styled.div`
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 0;
`;

const GoalKpi = ({ title, value, tooltip }) => {
  const elm = (
    <GoalKpiContainer>
      <div>{title}</div>
      <div style={{ fontWeight: "bold", fontSize: "1rem" }}>{value}</div>
    </GoalKpiContainer>
  );
  return tooltip ? <Tooltip overlay={tooltip}>{elm}</Tooltip> : elm;
};

const getPercentageColor = (percent) =>
  percent === 100
    ? lighten(0.2, theme.colors.success)
    : percent > 80
    ? lighten(0.4, theme.colors.success)
    : theme.colors.primary;

const GoalProgress = ({ percent }) => {
  return (
    <GoalProgressBar>
      <GoalProgressBarPercent
        percent={percent}
        color={getPercentageColor(percent)}
      />
      <GoalProgressBarLabel>{percent}%</GoalProgressBarLabel>
    </GoalProgressBar>
  );
};

export {
  GoalViewContainer,
  GoalViewName,
  GoalKpiContainer,
  GoalKpi,
  GoalProgress,
};

import styled, { css } from "styled-components";

const OverflowContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: ${({ noOverflow }) => !noOverflow && "auto"};
  ${({ titleWithBody, titleWithBodyAndActions }) =>
    titleWithBody
      ? css`
          display: grid;
          grid-template-rows: auto 1fr;
          grid-gap: 1rem;
        `
      : titleWithBodyAndActions &&
        css`
          display: grid;
          grid-template-rows: auto 1fr auto;
          grid-gap: 1rem;
        `}
`;

export default OverflowContainer;

import {
  routing,
  linkPlaceholders,
  Button,
  generateLinkWithParams,
  Link,
} from "common";
import { useParams } from "react-router-dom";

const ViewOrdersLink = ({ dealByDeal, isAdminUser }) => {
  const { brandId } = useParams();
  const isDisabled = dealByDeal && !isAdminUser;
  return (
    <Link
      to={generateLinkWithParams(routing.crm.viewBrandOrders, {
        [linkPlaceholders.brandId]: brandId,
      })}
    >
      <Button
        fit
        style={{
          fontSize: "1rem",
          opacity: isDisabled ? 0.7 : 1,
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
        disabled={isDisabled}
      >
        View Orders
      </Button>
    </Link>
  );
};

const ViewOffersLink = ({ dealByDeal, isAdminUser }) => {
  const { brandId } = useParams();
  const isDisabled = dealByDeal && !isAdminUser;

  return (
    <Link
      to={generateLinkWithParams(routing.crm.viewBrandOffers, {
        [linkPlaceholders.brandId]: brandId,
      })}
    >
      <Button
        fit
        style={{
          fontSize: "1rem",
          opacity: isDisabled ? 0.7 : 1,
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
        disabled={isDisabled}
        // style={{ fontSize: "1rem" }}
      >
        View Offers
      </Button>
    </Link>
  );
};

const ViewBrandLink = () => {
  const { brandId } = useParams();

  return (
    <Link
      to={generateLinkWithParams(routing.crm.viewBrand, {
        [linkPlaceholders.brandId]: brandId,
      })}
    >
      <Button fit style={{ fontSize: "1rem" }}>
        View Brand
      </Button>
    </Link>
  );
};

const ViewCustomersLink = ({ dealByDeal, isAdminUser }) => {
  const { brandId } = useParams();
  const isDisabled = dealByDeal && !isAdminUser;

  return (
    <Link
      to={generateLinkWithParams(routing.crm.viewBrandCustomers, {
        [linkPlaceholders.brandId]: brandId,
      })}
    >
      <Button
        fit
        style={{
          fontSize: "1rem",
          opacity: isDisabled ? 0.7 : 1,
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
        disabled={isDisabled}
      >
        {" "}
        View Customers
      </Button>
    </Link>
  );
};

export { ViewBrandLink, ViewCustomersLink, ViewOrdersLink, ViewOffersLink };

import styled from "styled-components";

const GoalType = styled.h3`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const GoalSection = styled.div`
  margin: 1rem 0;
`;

export { GoalType, GoalSection };

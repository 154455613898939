import {
  Button,
  DividerHorizontal,
  Dropdown,
  FullPageLoad,
  ItemSplitter,
  OverflowContainer,
  RowFlex,
  theme,
  useAxios,
  useModalContext,
} from "common";
import { format, startOfMonth } from "date-fns";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import AddGoal from "./AddGoal";
import { GoalView, ProgressView } from "./GoalView";
import { eBrandGoalType, eBrandGoalTypeDescription } from "./static";
import { GoalSection, GoalType } from "./styles";

const years = [...Array(10)].map((_, i) => 2020 + i);
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const filterGoals = (month, year, goals = []) =>
  goals.filter((g) => {
    const d = new Date(g.month);
    const m = d.getMonth();
    const y = d.getFullYear();
    return m === month && y === year;
  });

const Goals = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [openNewGoals, setOpenNewGoals] = useState(false);

  const thisDate = startOfMonth(new Date(year, month, 5));

  const { closeModal, setModalContent } = useModalContext();
  const { response, refetch } = useAxios({
    callOnLoad: { method: "GET", url: "/brandGoals" },
  });

  const { callAxios, loading } = useAxios({
    onComplete: () => {
      refetch();
    },
  });

  if (!response) return <FullPageLoad fillWidth />;

  const allGoals = response.data;

  const goalTotal = { sos: [], currentAmount: 0, goalAmount: 0 };
  const monthGoalTotal = { sos: [], currentAmount: 0, goalAmount: 0 };

  for (const goal of allGoals) {
    const d = new Date(goal.month);
    const m = d.getMonth();
    const y = d.getFullYear();

    const isYear = year === y;
    if (!isYear) continue;

    goalTotal.sos.push(...(goal.sos ?? []));
    goalTotal.currentAmount += goal.currentAmount ?? 0;
    goalTotal.goalAmount += goal.goalAmount ?? 0;

    const isMonth = m === month;
    if (!isMonth) continue;

    monthGoalTotal.sos.push(...(goal.sos ?? []));
    monthGoalTotal.currentAmount += goal.currentAmount ?? 0;
    monthGoalTotal.goalAmount += goal.goalAmount ?? 0;
  }

  const goals = filterGoals(month, year, allGoals);

  return (
    <OverflowContainer>
      <ItemSplitter>
        <ProgressView
          {...goalTotal}
          title={
            <div style={{ color: theme.colors.primary }}>{year} Total</div>
          }
        />
        <ProgressView
          {...monthGoalTotal}
          title={
            <div style={{ color: theme.colors.primary }}>
              {format(thisDate, "MMMM")} Total
            </div>
          }
        />
      </ItemSplitter>
      <ItemSplitter split={13}>
        {months.map((m, idx) => {
          return (
            <RowFlex center key={m}>
              <Button secondary={month !== idx} onClick={() => setMonth(idx)}>
                <div>
                  <div>{m}</div>
                </div>
              </Button>
            </RowFlex>
          );
        })}
        <Dropdown
          label="Year"
          value={year}
          options={years.map((value) => ({ value, label: value }))}
          onChange={(v) => setYear(v)}
        />
      </ItemSplitter>
      {Object.values(eBrandGoalType)
        .filter((t) => openNewGoals || t !== eBrandGoalType.NEW)
        .map((type) => {
          const goalProps = {
            type,
            close: closeModal,
            onCompleted: () => {
              refetch();
              closeModal();
            },
          };
          const typeGoals = goals.filter((g) => g.type === type);
          const typeGoalTotal = { sos: [], currentAmount: 0, goalAmount: 0 };
          const typeGoalElements = typeGoals.map((goal) => {
            typeGoalTotal.sos.push(...(goal.sos ?? []));
            typeGoalTotal.currentAmount += goal.currentAmount ?? 0;
            typeGoalTotal.goalAmount += goal.goalAmount ?? 0;
            return (
              <GoalView
                key={goal.id}
                goal={goal}
                onEdit={() =>
                  setModalContent(<AddGoal {...goalProps} goal={goal} />)
                }
                loading={loading}
                onDelete={() => {
                  callAxios({
                    method: "DELETE",
                    url: `/brandGoals/${goal.id}`,
                  });
                }}
              />
            );
          });
          return (
            <GoalSection key={type}>
              <GoalType>
                <RowFlex extend>
                  {eBrandGoalTypeDescription[type]}
                  <Button
                    fit
                    onClick={() =>
                      setModalContent(
                        <AddGoal {...goalProps} thisDate={thisDate} />
                      )
                    }
                  >
                    <FaPlus size="1rem" />
                  </Button>
                </RowFlex>
              </GoalType>
              <ItemSplitter split={6}>
                <ProgressView
                  {...typeGoalTotal}
                  title={
                    <div style={{ color: theme.colors.primary }}>
                      Total {type}
                    </div>
                  }
                />
                {typeGoalElements}
              </ItemSplitter>
              <DividerHorizontal pad />
            </GoalSection>
          );
        })}
      <RowFlex>
        <FaPlus onClick={() => setOpenNewGoals((p) => !p)} cursor="pointer" />{" "}
        {openNewGoals ? "Close" : "Open"} New Goals
      </RowFlex>
    </OverflowContainer>
  );
};

export default Goals;

import {
  Button,
  DatePicker,
  Dropdown,
  formatNumber,
  FormikSubmit,
  FullPageLoad,
  getDateWithHumanReadableTime,
  ItemSplitter,
  OverflowContainer,
  sortAndFormatOptions,
  Table,
  TextField,
  theme,
  useAxios,
  useToast,
} from "common";
import { Field, Formik } from "formik";
import { eBrandGoalType, isBrandOpen } from "../static";
import { formikSchema, formikSchemaForCreate } from "./formikSchema";
import { omit, orderBy } from "lodash";
import { FaPlus, FaTrash } from "react-icons/fa";

const AddGoal = ({ type, close, onCompleted, goal, thisDate }) => {
  const { alertSuccess, onError } = useToast();
  const { response } = useAxios({
    callOnLoad: {
      method: "GET",
      url: "/brands",
      params: {
        limit: 100000,
        select: "name purchaseOrders saleOrders",
        populate: JSON.stringify([
          {
            path: "saleOrders",
            select: ["OrderNumber", "Amount"],
          },
          {
            path: "purchaseOrders",
            select: ["Status", "Amount", "PODate"],
          },
        ]),
      },
    },
  });

  const { callAxios, loading } = useAxios();

  const doMany = async (goals) => {
    await Promise.all(
      goals.map((data) =>
        callAxios({
          method: "POST",
          url: `/brandGoals`,
          data: omit(data, ["id"]),
        })
      )
    );
  };

  if (!response) return <FullPageLoad fillWidth />;

  const allBrands = response.data.data;
  const brandsToUse =
    type === eBrandGoalType.NEW
      ? allBrands
      : allBrands.filter((b) => {
          const isOpen = isBrandOpen(b);
          return type === eBrandGoalType.OPEN ? isOpen : !isOpen;
        });

  const addEdit = !goal ? "Add" : "Edit";

  const brandOptions = sortAndFormatOptions({
    list: brandsToUse,
    labelKey: (b) => {
      const posByDate = orderBy(
        b.purchaseOrders,
        (p) => new Date(p.PODate),
        "desc"
      );
      const latestPo = posByDate[0];
      const latestDate = getDateWithHumanReadableTime(latestPo?.PODate);
      const latestAmount = formatNumber(latestPo?.Amount);
      const latest = latestPo ? ` (${latestDate} - $${latestAmount})` : "";
      return `${b.name}${latest}`;
    },
    valueKey: "id",
  });

  return (
    <Formik
      {...(goal
        ? formikSchema(type, goal, thisDate)
        : formikSchemaForCreate(type, thisDate))}
      onSubmit={(data) => {
        if (goal)
          return callAxios({
            method: "PUT",
            url: `/brandGoals/${goal.id}`,
            data,
          })
            .then(() => {
              alertSuccess("Goal Modified");
              onCompleted();
            })
            .catch(onError);

        doMany(data.brands)
          .then(() => {
            alertSuccess("Goal Modified");
            onCompleted();
          })
          .catch(onError);
      }}
    >
      {({ values, handleChange }) => {
        const goalBrand = brandsToUse.find((btu) => btu.id === values.brand);
        const brandSosToUse = sortAndFormatOptions({
          list: goalBrand?.saleOrders ?? [],
          valueKey: "id",
          labelKey: (v) => `${v.OrderNumber} ($${formatNumber(v.Amount)})`,
        });
        return (
          <OverflowContainer titleWithBodyAndActions>
            <h3>
              {addEdit} {type} Goal
            </h3>
            {goal ? (
              <div>
                <ItemSplitter>
                  <Field
                    name="brand"
                    label="Brand"
                    required
                    component={Dropdown}
                    options={brandOptions}
                  />
                  <Field
                    name="month"
                    component={DatePicker}
                    label="Month"
                    required
                    showMonthYearPicker
                  />
                  <Field
                    name="goalAmount"
                    component={TextField}
                    type="number"
                    label="Goal Amount"
                    prefix="$"
                    required
                  />
                  <Field
                    disabled={!values.brand}
                    name="customSos"
                    component={Dropdown}
                    isMulti
                    options={brandSosToUse}
                  />
                  <Field
                    name="notes"
                    component={TextField}
                    isArea
                    label="Notes"
                  />
                </ItemSplitter>
              </div>
            ) : (
              <OverflowContainer>
                <Table>
                  <thead>
                    <tr>
                      <th>Brand</th>
                      <th>Month</th>
                      <th style={{ width: "1%" }}>Goal Amount</th>
                      <th>Notes</th>
                      <th>Additional SOs</th>
                      <th style={{ width: "1%" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {values.brands.map((br, idx) => {
                      const thisBrand = brandsToUse.find(
                        (btu) => btu.id === br.brand
                      );
                      const sosToUse = sortAndFormatOptions({
                        list: thisBrand?.saleOrders ?? [],
                        valueKey: "id",
                        labelKey: (v) =>
                          `${v.OrderNumber} ($${formatNumber(v.Amount)})`,
                      });
                      return (
                        <tr key={br.id}>
                          <td>
                            <Field
                              disabled={br.customSos?.length > 0}
                              name={`brands[${idx}].brand`}
                              required
                              component={Dropdown}
                              options={brandOptions}
                            />
                          </td>
                          <td>
                            <Field
                              name={`brands[${idx}].month`}
                              component={DatePicker}
                              required
                              showMonthYearPicker
                            />
                          </td>
                          <td>
                            <Field
                              name={`brands[${idx}].goalAmount`}
                              component={TextField}
                              type="number"
                              prefix="$"
                              required
                            />
                          </td>
                          <td>
                            <Field
                              name={`brands[${idx}].notes`}
                              component={TextField}
                            />
                          </td>
                          <td>
                            <Field
                              disabled={!thisBrand}
                              name={`brands[${idx}].customSos`}
                              component={Dropdown}
                              isMulti
                              options={sosToUse}
                            />
                          </td>
                          <td>
                            <FaTrash
                              color={theme.colors.danger}
                              cursor="pointer"
                              onClick={() =>
                                handleChange({
                                  target: {
                                    name: "brands",
                                    value: values.brands.filter(
                                      (b) => b.id !== br.i
                                    ),
                                  },
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={5}>
                        <FaPlus
                          cursor="pointer"
                          onClick={() =>
                            handleChange({
                              target: {
                                name: "brands",
                                value: [
                                  ...values.brands,
                                  {
                                    type,
                                    month: thisDate,
                                    id: `${Math.ceil(
                                      Math.random() * 100000000
                                    )}`,
                                  },
                                ],
                              },
                            })
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </OverflowContainer>
            )}
            <ItemSplitter>
              <Button secondary onClick={close}>
                Cancel
              </Button>
              <FormikSubmit loading={loading}>{addEdit}</FormikSubmit>
            </ItemSplitter>
          </OverflowContainer>
        );
      }}
    </Formik>
  );
};

export default AddGoal;
